<template>
  <v-container class="pa-0 ma-0 fill-height text-left" fluid>
    <v-row justify="start">
      <v-col cols="12">
        <v-card class="d-flex fill-height fluid pa-0 ma-0" flat tile width="100%">
          <v-scroll-x-transition appear>
            <v-img aspect-ratio="2" max-height="150" src="@/assets/images/doctors_header.jpg"
                   transition="slide-x-transition" width="100%">
              <v-container class="fill-height align-end bottom-gradient" fluid>
                <v-row align="center" justify="center">
                  <v-col>
                    <div class="white--text pb-10">
                      <span class="pl-1 pl-md-8 text-h5 text-md-h4 text-uppercase font-weight-black"
                            style="text-shadow: 2px 2px 10px black">
                        Only the best excellence doctors
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-scroll-x-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <div class="boxdivider"></div>
    </v-row>
    <v-row justify="center">
      <SearchBar :data="doctors" @search="updateFilteredData"/>
    </v-row>
    <v-row justify="center" class="ma-10" v-if="doctors.length === 0">
      <div style="text-align: center;">
        <div>
          {{ this.message }}
        </div>
        <div style="display: inline-block;margin-top: -15px;">
          <v-img src="@/assets/images/loading_new.gif" width="100px"></v-img>
        </div>
      </div>
    </v-row>
    <v-row v-if="doctors.length !== 0" justify="center">
      <p class="text-button"
         style="text-align: center;font-size: 20px !important; font-weight: bold;margin-bottom: -10px;">Accredited
        Doctors of Excellence of Baja
        California by COEPRIS and REPSSABI</p>
    </v-row>
    <v-row align="center" justify="center">
      <div class="fill-height container--fluid mb-8 mx-8" style="width: 100%">
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="start" style="padding: 30px">
            <v-col v-for="(item, index) in visibleCards" :key="index"
                   class="d-flex child-flex mb-5" cols="12" sm="6" md="3" :class="item.premium?'':'d-none'">
              <v-card hover class="rounded-lg" v-if="item.active">
                <div style="cursor: pointer; height: 300px; overflow: hidden" v-on:click="showProfile(item.id)">
                  <v-img :lazy-src="require('@/assets/images/no-picture.jpg')" :src="
                      item.pictures[0] != null
                        ? item.pictures[0].url
                        : require('@/assets/images/no-picture.jpg')
                    " class="rounded-t-lg" position="top" style="object-fit: cover">
                    <template v-slot:placeholder>
                      <v-row align="center" class="fill-height ma-0" justify="center">
                        <v-progress-circular color="grey lighten-5" indeterminate>
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <img v-if="item.premium===1" src="@/assets/images/icons/premium.png"
                       alt="sello"
                       class="superior-derecha"/>
                  <img v-else src="@/assets/images/icons/member.png"
                       alt="sello"
                       class="superior-derecha"/>
                  <img v-show="item.excellence===1" src="@/assets/images/icons/escudo_coepris.f8331f99.png" alt="sello1"
                       class="superior-derecha-segunda"/>
                  <img v-show="item.repsabi===1" src="@/assets/images/icons/repssabi.png" alt="sello1"
                       class="superior-derecha-tercera"/>
                  <img v-show="item.repsabi===1" src="@/assets/images/icons/coparmex.png" alt="sello1"
                       class="superior-derecha-cuarta"/>
                </div>
                <v-btn v-model="selectedId" class="mx-0 mt-1" elevation="0" plain text @click="showProfile(item.id)">
                  <span class="pb-0 indigo--text text-subtitle-2 text-truncate" v-text="item.name"></span>
                </v-btn>

                <div class="row pt-0 mt-0">
                  <div class="col-12">
                    <v-card-subtitle class="pb-0 pt-0 text-subtitle-2 indigo--text text-capitalize text-truncate">
                      <router-link :to="`/specialty-profile/${item.specialties[0]?.id}`">
                        <span v-if="item.specialties[0] != null">
                          {{
                            item.specialties[0].name_english
                                ? item.specialties[0].name_english
                                : item.specialties[0].name
                          }}
                        </span>
                        <span v-else> Not assigned </span>
                      </router-link>
                    </v-card-subtitle>
                  </div>
                </div>
                <v-card-actions class="mt-1 justify-center">
                  <v-btn v-show="item.facebook_profile!==null" :href="item.facebook_profile" color="blue"
                         icon target="_blank">
                    <v-icon color="primary" size="24px">mdi-facebook
                    </v-icon>
                  </v-btn>
                  <v-btn v-show="item.instagram_profile!==null" :href="item.instagram_profile"
                         color="#D93F8FFF" icon target="_blank">
                    <v-icon size="24px">mdi-instagram
                    </v-icon>
                  </v-btn>
                  <v-btn v-show="item.twitter_profile!==null" :href="item.twitter_profile" icon
                         target="_blank">
                    <i class="bi bi-twitter-x" style="font-size: 20px;"></i>
                  </v-btn>
                  <v-btn v-show="item.youtube_video!==null" :href="item.youtube_video" color="red" icon
                         target="_blank">
                    <v-icon size="24px">mdi-youtube
                    </v-icon>
                  </v-btn>
                  <v-btn v-show="item.tiktok!==null" :href="item.tiktok" color="red" icon target="_blank">
                    <img src="@/assets/images/icons/ticktock.png" alt="icon" width="25" height="25">
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>

    <v-row justify="center" v-if="doctors.length !== 0 && this.membersVisibles">
      <p class="text-button" style="font-size: 22px !important; font-weight: bold;margin-bottom: -10px;">List of Member Doctors</p>
    </v-row>
    <v-row justify="center" class="ma-10" v-if="doctors.length !== 0 && !this.membersVisibles">
      <div style="text-align: center;">
        <div>
          {{ this.message_members }}
        </div>
        <div style="display: inline-block;margin-top: -15px;">
          <v-img src="@/assets/images/loading_new.gif" width="100px"></v-img>
        </div>
      </div>
    </v-row>
    <v-row align="center" justify="center" v-if="this.membersVisibles">
      <div class="fill-height container--fluid mb-8 mx-8" style="width: 100%">
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="start" style="padding: 30px">
            <v-col v-for="(item, index) in filteredData" :key="index"
                   class="d-flex child-flex mb-5" cols="12" sm="6" md="3" :class="item.premium?'d-none':''">
              <v-card hover class="rounded-lg" v-if="item.active">
                <div style="cursor: pointer; height: 300px; overflow: hidden" v-on:click="showProfile(item.id)">
                  <v-img :lazy-src="require('@/assets/images/no-picture.jpg')" :src="
                          item.pictures[0] != null
                            ? item.pictures[0].url
                            : require('@/assets/images/no-picture.jpg')
                        " class="rounded-t-lg" position="top" style="">
                    <!-- <template v-slot:placeholder>
                      <v-row align="center" class="fill-height ma-0" justify="center">
                        <v-progress-circular color="grey lighten-5" indeterminate>
                        </v-progress-circular>
                      </v-row>
                    </template> -->
                  </v-img>
                  <img v-if="item.basic===1" src="@/assets/images/icons/basic.png"
                       alt="sello"
                       class="superior-derecha"/>
                  <img v-else-if="item.premium===1" src="@/assets/images/icons/premium.png"
                       alt="sello"
                       class="superior-derecha"/>
                  <img v-else src="@/assets/images/icons/member.png"
                       alt="sello"
                       class="superior-derecha"/>
                  <img v-show="item.excellence===1" src="@/assets/images/icons/escudo_coepris.f8331f99.png" alt="sello1"
                       class="superior-derecha-segunda"/>
                  <img v-show="item.repsabi===1" src="@/assets/images/icons/repssabi.png" alt="sello1"
                       class="superior-derecha-tercera"/>
                  <img v-show="item.coparmex" src="@/assets/images/icons/coparmex.png" alt="sello1"
                       class="superior-derecha-cuarta"/>
                </div>
                <v-btn v-model="selectedId" class="mx-0 mt-1" elevation="0" plain text @click="showProfile(item.id)">
                  <span class="pb-0 indigo--text text-subtitle-2 text-truncate" v-text="item.name"></span>
                </v-btn>

                <div class="row pt-0 mt-0">
                  <div class="col-12">
                    <v-card-subtitle class="pb-0 pt-0 text-subtitle-2 indigo--text text-capitalize text-truncate">
                      <router-link :to="`/specialty-profile/${item.specialties[0]?.id}`">
                            <span v-if="item.specialties[0] != null">
                              {{
                                item.specialties[0].name_english
                                    ? item.specialties[0].name_english
                                    : item.specialties[0].name
                              }}
                            </span>
                        <span v-else> Not assigned </span>
                      </router-link>
                    </v-card-subtitle>
                  </div>
                </div>
                <v-card-actions class="mt-1 justify-center">
                  <v-btn v-show="item.facebook_profile!==null" :href="item.facebook_profile" color="blue"
                         icon target="_blank">
                    <v-icon color="primary" size="24px">mdi-facebook
                    </v-icon>
                  </v-btn>
                  <v-btn v-show="item.instagram_profile!==null" :href="item.instagram_profile"
                         color="#D93F8FFF" icon target="_blank">
                    <v-icon size="24px">mdi-instagram
                    </v-icon>
                  </v-btn>
                  <v-btn v-show="item.twitter_profile!==null" :href="item.twitter_profile" icon
                         target="_blank">
                    <i class="bi bi-twitter-x" style="font-size: 20px;"></i>
                  </v-btn>
                  <v-btn v-show="item.youtube_video!==null" :href="item.youtube_video" color="red" icon
                         target="_blank">
                    <v-icon size="24px">mdi-youtube
                    </v-icon>
                  </v-btn>
                  <v-btn v-show="item.tiktok!==null" :href="item.tiktok" color="red" icon target="_blank">
                    <img src="@/assets/images/icons/ticktock.png" alt="icon" width="25" height="25">
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import doctorsService from "@/providers/DoctorsService";
import SearchBar from "../searchBar/SearchBar.vue";

export default {
  name: "DoctorsComponent",

  components: {
    SearchBar,
  },

  props: {
    unique: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    membersVisibles: false,
    selectedId: 0,
    itemsPerPage: 8,
    doctors: [],
    filteredData: [],
    transparent: "rgba(255, 255, 255, 0)",
    message: "Loading our list of excellence doctors, please be patient ...",
    message_members: "Loading our list of members doctors, please be patient ..."
  }),
  computed: {
    visibleCards() {
      return this.filteredData.slice(0, this.itemsPerPage);
    }
  },
  methods: {
    isFechaVencida(fecha) {
      const fechaVencimiento = fecha.split("T")[0];
      const fechaActual = new Date().toISOString().split("T")[0];
      return fechaVencimiento > fechaActual;
    },
    getDoctors() {
      doctorsService.getViewListByNumber(this.unique, false, true).then((record) => {
        this.doctors = record.value;

        this.filteredData = [...this.doctors];
        this.visibleCards = this.filteredData.slice(0, this.itemsPerPage);
        if (this.doctors.length === 0) {
          this.message = "There are no Doctors in the catalogue...";
        }
      });
    },
    updateFilteredData(filteredData) {
      this.filteredData = filteredData;
    },
    showProfile(idDoctor) {
      this.selectedId = idDoctor;
      this.$router.push({
        name: "DoctorProfile",
        params: {
          id: idDoctor,
        },
      });
    },
    handleScroll() {
      this.itemsPerPage += 1
      if (this.itemsPerPage >= 30) {
        this.membersVisibles = true
      }
    }
  },
  mounted() {
    this.doctors = [];
    this.filteredData = [];
    this.getDoctors();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // Remueve el listener del evento de scroll
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.bottom-gradient {
  background-image: linear-gradient(
      to bottom,
      rgba(116, 148, 153, 0.29) 0%,
      rgba(15, 18, 21, 0.7) 100%
  );
  opacity: 1;
  background-color: transparent;
  background-blend-mode: normal;
}

#my-lax .v-parallax__content {
  background: linear-gradient(45deg, black, transparent);
}

.v-btn {
  text-transform: none !important;
}

.blend-opacity {
  opacity: 0.5;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: normal;
}

/* .blend-opacity:hover {
         opacity: 0.9;
         transition: opacity 0.4s ease-in-out;
         background-color: black;
         background-blend-mode: darken;
     }*/
.boxdivider {
  background-color: transparent;
  width: 100px;
  height: 50px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}

.superior-derecha {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 80px;
  height: 80px;
}

.superior-derecha-segunda {
  position: absolute;
  top: 50px;
  right: 3px;
  width: 90px;
  height: 90px;
}

.superior-derecha-tercera {
  position: absolute;
  top: 150px;
  right: 12px;
  width: 65px;
  height: 65px;
}
.superior-derecha-cuarta {
  position: absolute;
  top: 230px;
  right: 12px;
  width: 65px;
  height: 65px;
  border-radius: 50px;
}

@media (max-width: 767px) {
  .superior-derecha {
    position: absolute;
    top: -30px;
    right: -25px;
    width: 90px;
    height: 80px;
  }

  .superior-derecha-segunda {
    position: absolute;
    top: 50px;
    right: -3px;
    width: 95px;
    height: 95px;
  }

  .superior-derecha-tercera {
    position: absolute;
    top: 145px;
    right: 7px;
    width: 70px;
    height: 70px;
  }
}

.d-none {
  display: none !important;
}
</style>
